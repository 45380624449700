<template>
  <span id="LoginTemplate">
    <main>
      <div class="container-grid">
        <div class="background" v-if="!isMobile">
          <div class="wave"></div>
          <p class="text1">
            <svg
              class="mr-2"
              width="24"
              height="20"
              viewBox="0 0 24 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 14V1H1V14H16ZM16 14H23V9L20 6H16V14ZM8 16.5C8 17.8807 6.88071 19 5.5 19C4.11929 19 3 17.8807 3 16.5C3 15.1193 4.11929 14 5.5 14C6.88071 14 8 15.1193 8 16.5ZM21 16.5C21 17.8807 19.8807 19 18.5 19C17.1193 19 16 17.8807 16 16.5C16 15.1193 17.1193 14 18.5 14C19.8807 14 21 15.1193 21 16.5Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Greeen Envios
          </p>
          <div class="grid-box">
            <div class="box1"></div>
            <div class="box2">
              <svg
                width="25"
                height="21"
                viewBox="0 0 25 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.3887 2L22.9998 10.6111L14.3887 19.2222"
                  stroke="#2133D2"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2 2.00003L10.6111 10.6111L2 19.2223"
                  stroke="#2133D2"
                  stroke-opacity="0.3"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="form" :class="{'mobile-form':isMobile}">
          <div class="form">
            <GridColum :tamanho="12" :mt="''" class="">
              <slot></slot>
            </GridColum>
          </div>
          <FooterBase />
        </div>
      </div>
    </main>
  </span>
</template>

<script>
import GridColum from "@/components/base/GridColum.vue";
import FooterBase from "@/components/base/Footer.vue";

export default {
  name: "LoginTemplate",
  components: {
    GridColum,
    FooterBase,
  },
  data() {
    return {
      client: {
        width: 0,
      },
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    mobileResize() {
      var e = this.client.width;
      if (e < 765) {
        return 10;
      } else {
        return 6;
      }
    },
    handleResize() {
      this.client.width = window.innerWidth;
      this.mobileResize();
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "../assets/scss/main.scss";

.container-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.background {
  background: #2133d2;
  height: 100vh;
  width: 100%;
  .wave {
    position: absolute;
    margin: 0;
    width: 40%;
    height: 100%;
    background: linear-gradient(
      215.84deg,
      rgba(255, 255, 255, 0.3) 8.34%,
      rgba(255, 255, 255, 0) 75.73%
    );
    border-radius: 20px;
    transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
  }
}

.text1 {
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  margin-top: 10vh;
  margin-left: 30px;
  white-space: nowrap;
}

.grid-box {
  display: flex;
  gap: 22px;
  margin-top: 5vh;
  margin-left: 30px;
  .box1 {
    width: 64.77px;
    height: 64.77px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.3) -10.29%,
      rgba(255, 255, 255, 0.19543) 23.09%,
      rgba(255, 255, 255, 0) 82.38%
    );
    border-radius: 5px;
  }
  .box2 {
    width: 64.77px;
    height: 64.77px;
    background: #ffffff;
    box-shadow: 10px 10px 20px rgba(37, 38, 49, 0.1);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.form {
  margin: auto;
}
</style>
